<template>
  <a-upload v-model:file-list="state.fileList" :action="action" withCredentials :before-upload="beforeUpload">
    <a-button class="block" v-if="state.fileList.length == 0">
      <p>
        <CloudUploadOutlined class="icon" />
      </p>
      <p>点击或将附件拖拽到这里上传</p>
    </a-button>
    <!-- <div class="block" v-if="state.fileList.length == 0">
      <p class="ant-upload-drag-icon">
        <CloudUploadOutlined />
      </p>
      <p class="ant-upload-text">点击或将附件拖拽到这里上传</p>
    </div> -->
  </a-upload>
  <p v-if="tips" class="tips">（可上传图片、文档等类型文件，单个文件大小不能超过10M）</p>
</template>

<script>
import { reactive, defineComponent, watch } from 'vue'
import { cmsNotice } from '@/utils/utils'
import { CloudUploadOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'dragger-upload',
  components: {
    CloudUploadOutlined
  },
  props: {
    count: {
      type: Number,
      default: 1
    },
    fileList: {
      type: Array,
      default: () => []
    },
    action: {
      type: String,
      default: ''
    },
    fileType: {
      type: Array,
      default: () => ['xls', 'xlsx']
    },
    size: {
      type: Number,
      default: 10
    },
    minSzieByte: {
      type: Number,
      default: 6
    },
    tips: {
      type: Boolean,
      default: true
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const state = reactive({
      fileList: []
    })
    const beforeUpload = file => {
      emit('update:fileList', [file])
      // console.log(file)
      props.callback(file)
      return false
    }

    const validateFile = () => {
      if (state.fileList.length === 0) {
        cmsNotice('error', '请选择要导入的文件')
        return false
      }
      const suffix = state.fileList[0].name.split('.').pop()
      const size = Math.floor(state.fileList[0].size / (1024 * 1024))
      if (state.size && size > props.size) {
        cmsNotice('warn', `大小不能超过${props.size}M`)
        // emit('update:loading', false)
        return false
      }
      if (state.fileList[0].size <= props.minSzieByte) {
        cmsNotice('error', `大小应大于${props.minSzieByte}个字节`)
        return false
      }
      if (!props.fileType.includes(suffix)) {
        cmsNotice('warn', `格式错误，仅支持${props.fileType.join(',')}格式`)
        // emit('update:loading', false)
        return false
      }

      return true
    }

    watch(
      () => state.fileList.length,
      () => emit('update:fileList', state.fileList)
    )
    watch(
      () => props.fileList.length,
      () => (state.fileList = props.fileList)
    )

    return {
      beforeUpload,
      state,
      validateFile
    }
  }
})
</script>

<style lang="scss" scoped>
.tips {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  width: 520px;
  margin-top: 8px;
}
.block {
  width: 512px;
  height: 168px;
  text-align: center;
  font-size: 16px;
  color: #333333;
  background: #fafafa;
  border: 1px dashed #dddddd;
  border-radius: 4px;
  margin-bottom: 16px;
  .icon {
    color: #c3161c;
    margin-bottom: 20px;
    font-size: 50px;
  }
}
</style>
